import React, { useState } from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';
import Story from "../components/story-components/story";
import GalleryFullScreen from "../components/gallery/gallery-fullscreen";


const StoryPage = ({ data, location }) => {
  const [fullScreenArray, setFullScreenArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const content = data.content.frontmatter || {};
  const html = data.content.html;
  const story = data.story.edges.map(element => element.node.childMarkdownRemark);

  const enterFullScreen = (payload) => {
    setCurrentIndex(0);
    setFullScreenArray(payload);
  }

  const handleNext = (event) => {
    event.preventDefault();
    const next = currentIndex + 1 >= fullScreenArray.length ? 0 : currentIndex + 1;
    setCurrentIndex(next);
  }

  const handlePrev = (event) => {
    event.preventDefault();
    const prev = currentIndex <= 0 ? fullScreenArray.length - 1 : currentIndex - 1;
    setCurrentIndex(prev);
  }

  const handleClose = (event) => {
    event.preventDefault();
    setFullScreenArray([]);
    setCurrentIndex(0);
  }

  return (
    <>
      {fullScreenArray.length > 0
        ? (
          <GalleryFullScreen
            data={{
              type: fullScreenArray[currentIndex].type,
              image: fullScreenArray[currentIndex].image.childImageSharp.fullscreen,
              youtube: fullScreenArray[currentIndex].youtube,
            }}
            onNext={handleNext}
            onPrev={handlePrev}
            onClose={handleClose}
            disableNavigation={fullScreenArray.length === 1}
          />
        )
        : ''}
      <SEO
        canonical="/story/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <br />
          <PageHTML center dangerouslySetInnerHTML={{ __html: html }} />
          <br />
          <div>
            {story.map((story, index) => (
              <Story
                key={index}
                story={story}
                onGalleryPress={enterFullScreen}
              />
            )
            )}
          </div>

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>

    </>
  );
}
export default StoryPage;


export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  content: markdownRemark(fileAbsolutePath: {regex: "/content/pages/story.md/"}) {
    frontmatter {
      title
    }
    html
  }
  story: allFile(filter: {absolutePath: {regex: "/content/story//"}, childMarkdownRemark: {frontmatter: {is_visible: {eq: true}}}}, sort: {fields: childrenMarkdownRemark___frontmatter___date, order: DESC}) {
    edges {
      node {
        childMarkdownRemark {
          frontmatter {
            date
            show_date
            gallery {
              type
              image{ 
                childImageSharp {
                  thumb: gatsbyImageData(
                    width: 187
                    height: 140
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                  fullscreen: gatsbyImageData(
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }            
          }
          html
        }
      }
    }
  }
}  
`;

