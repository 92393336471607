import {
  GatsbyImage,
} from "gatsby-plugin-image";
import {
  ZWrapper,
} from '../../ui-components';
import styled, { css } from "styled-components";


const cutSize = 4;
const btnSize = 32;

export const StoryWrap = styled.div`
display: table;
width: 100%;
margin-bottom: 1px;
text-align: left;

@media ${({theme}) => theme.device.mobile}{
  display: block;
}
`;

export const StoryWrapCell = styled.div`
display: table-cell;
vertical-align: top;

@media ${({theme}) => theme.device.mobile}{
  display: block;
}

${props => props.date && css`
  position: relative;
  text-align: center;
  width: 140px;
  padding-top: 18px;
  font-family: "Quantico", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 21px;
  color: ${({theme}) => theme.text.primary};

  @media ${({theme}) => theme.device.mobile}{
    width: 100%;
    padding-bottom: 16px;
  }
`}

${props => props.text && css`
  padding: 0 10px;
`}
`;

export const StoryBlock = styled.div`
display: table;
width: 100%;
margin-top: -7px;
margin-left: 7px;
margin-bottom: -7px;

@media only screen and (max-width: 620px){
  display: flex;
  flex-direction: column-reverse;
  align-content: center;
  align-items: center;
  width: auto;
  margin: auto;
  margin-left: -4px;
  margin-top: -4px;
  /* 
  margin-right: -7px; */
}
`;

export const StoryBlockCell = styled.div`
display: table-cell;
vertical-align: top;

@media only screen and (max-width: 620px){
  text-align: center;
  display: block;
}

${props => props.image && css`
  width: 240px;

  @media only screen and (max-width: 620px){
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }
`}

${props => props.text && css`
  padding: 8px 24px 16px 0px;

  @media only screen and (max-width: 620px){
    background-color: white;
    padding: 0;
    margin: 8px
    margin-top: 16px;

  }
`}
`;

export const StoryText = styled.div`
> p, ul, ol{
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: ${({theme}) => theme.text.primary};

  @media only screen and (max-width: 620px){
    line-height: 21px;
  }
}
`;
export const cutSize2 = 4;




export const StoryImageHover = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${btnSize - cutSize2 * 2}px;
  height: ${btnSize}px;
  margin-top: -${btnSize / 2}px;
  margin-left: -${btnSize / 2 - cutSize}px;
  text-align: center;
  z-index: 1;
  background-color: ${({theme}) => theme.thirdiary.main};

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-left: ${cutSize}px solid ${({theme}) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: -${cutSize}px;
    bottom: 0;
    border-top: ${cutSize}px solid transparent;
    border-right: ${cutSize}px solid ${({theme}) => theme.thirdiary.main};
    border-bottom: ${cutSize}px solid transparent;
  }

  svg{
    margin-top: 3px;
    fill: #fff;
  }
`;


export const StoryImageFrame = styled.button`
position: relative;
display: block;
line-height: 0;
z-index: 1;
background: transparent;
padding: 0;
margin: 0;
border: 0;
cursor: pointer;

&:hover{
    ${StoryImageHover}{
      display: block;
    }
    img{
      filter: brightness(60%);
    }
  }

&::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-top: ${cutSize2}px solid white;
  border-left: ${cutSize2}px solid transparent;
  border-bottom: ${cutSize2}px solid white;
  z-index: 100;
}

&::after {
  content: ' ';
  position: absolute;
  top: 0;
  left:0;
  bottom: 0;
  border-top: ${cutSize2}px solid white;
  border-right: ${cutSize2}px solid transparent;
  border-bottom: ${cutSize2}px solid white;
  z-index: 1;
}
`;

export const StoryImage = styled(GatsbyImage)`
width: 240px;
min-height: 180px;

@media only screen and (max-width: 620px){
  width: 260px;
  height: auto;
  margin: auto;
  
}
`

export const StoryImageCount = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 16px;
`

export const StoryImageCountDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 0 2px;
  background: #fff;
  display: inline-block;

  &:first-child{
    background: ${({theme}) => theme.thirdiary.main};
  }

`


export const Date = styled.div`
position: relative;
display: inline-block;
padding: 4px;
background-color: ${({theme}) => theme.background.default};
z-index: 4;
`

export const Dots = styled.div`
position: absolute;
top: 4px;
bottom: 0;
left: 50%;
border-left: 4px dotted rgba(0,0,0,0.1);
`;

export const StoryWrapper = styled(ZWrapper)`
  @media only screen and (max-width: 620px){
    max-width: 268px;
    margin: auto;
  }
`;
