import React from "react";
import {
  Icon,
} from '../../ui-components';
import {
  StoryBlock,
  StoryBlockCell,
  StoryImage,
  StoryImageFrame,
  StoryImageCount,
  StoryImageCountDot,
  StoryImageHover,
  StoryText,
  StoryWrap,
  StoryWrapCell,
  StoryWrapper,
  Date,
  Dots,
} from './story-components.styled'



const Story = ({ story, onGalleryPress }) => {
  const data = story.frontmatter || {}
  const html = story.html;
  const gallery = data.gallery || [];
  
  const previewImage = gallery.length > 0 ? gallery[0] : null;

  return (
    <StoryWrap>
      <StoryWrapCell date>
        {data.show_date
          ? <Date>{data.date}</Date>
          : ''
        }
        <Dots />
      </StoryWrapCell>
      <StoryWrapCell text>
        <StoryWrapper>
          <StoryBlock>
            <StoryBlockCell text>
              <StoryText dangerouslySetInnerHTML={{ __html: html }} />
            </StoryBlockCell>
            <StoryBlockCell image>
              {previewImage ?
                <StoryImageFrame onClick={() => { onGalleryPress(gallery) }}>
                  <StoryImage
                    image={previewImage.image.childImageSharp.thumb}
                    alt={data.date}
                  />
                  <StoryImageHover>
                    <Icon
                      name="search-outline"
                      width={24}
                      height={24}
                      noFill
                    />
                  </StoryImageHover>
                  <StoryImageCount>
                    {gallery.length > 1 && gallery.map((data, index) => (
                      <StoryImageCountDot key={index} />
                    ))}
                  </StoryImageCount>
                </StoryImageFrame>
                : null}
            </StoryBlockCell>
          </StoryBlock>
        </StoryWrapper>
      </StoryWrapCell>
    </StoryWrap>
  )

}

export default Story;
